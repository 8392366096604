import React from 'react';
import { Stack, Text, FontWeights, PrimaryButton, Label, mergeStyles } from '@fluentui/react';
import logo from './logo.png';
import './App.css';

declare global {
  interface Window { 
    CHELM_LINK: string;
    KOSNO_LINK: string;
  }
}

const boldStyle = { root: { fontWeight: FontWeights.semibold } };

const spaceS = mergeStyles({
  marginBottom: "80px",
})

export const App: React.FunctionComponent = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={{
        root: {
          margin: '0 auto',
          textAlign: 'center',
          height: '80%',
        },
      }}
      gap={25}
    >
      <img src={logo} alt="logo" />
      <div className={spaceS} >
        <Text variant="xxLarge" styles={boldStyle}>
          Rezerwacja dostaw
        </Text>
      </div>
      <Stack gap={25}>
        <Label styles={boldStyle}>
          Wybierz zakład
        </Label>
        <Stack horizontal gap={40} horizontalAlign="center">
          <PrimaryButton href={window.CHELM_LINK}>Zakład w Chełmie</PrimaryButton>
          <PrimaryButton href={window.KOSNO_LINK}>Zakład w Kośnie</PrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
